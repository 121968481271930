<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" style="width: 100%;">
    <el-card class="box-card" style="text-align: left;">
      <div slot="header" class="clearfix">
        <span>商户管理 / 查看</span>
      </div>
      <div>
        <el-form :inline="false" class="form" style="text-align: left;" label-width="150px" :model="item" :rules="rules"
                 ref="form">
              <el-form-item label="商户Logo" class="form-item" prop="merLogo">
                <el-upload
                    action=""
                    list-type="picture-card"
                    :http-request="afterReadLogo"
                    ref="merLogoUploader"
                    :limit="1"
                    :file-list="fileList"
                    :multiple="false"
                    :on-remove="handleRemove">
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{file}">
                    <img class="el-upload-list__item-thumbnail"
                         :src="file.url" alt="">
                    <span class="el-upload-list__item-actions">
                    <span  class="el-upload-list__item-delete" @click="handleRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                  </div>
                </el-upload>

              </el-form-item>

              <el-form-item label="商户名" class="form-item" prop="merName">
                <el-input v-model="item.merName" size="small"></el-input>
              </el-form-item>

              <el-form-item label="商铺地址" class="form-item" prop="merAddress">
                <el-input v-model="item.merAddress" placeholder="请填写商户地址" size="small"></el-input>
              </el-form-item>
        </el-form>

        <el-row style="text-align:center;padding-top: 20px;">
          <el-button type="button" class="el-button--primary" @click="submit" style="margin-right:20px;">确定
          </el-button>
          <router-link :to="{path: `/admin/merchant_manage/index`}">
            <el-button type="button" class="el-button--default">取消</el-button>
          </router-link>
        </el-row>
      </div>
    </el-card>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import OSS from "ali-oss";
import {uuid} from "vue-uuid";

export default {
  name: "edit",
  data() {
    return {
      fullscreenLoading: false,
      item: {
        merLogo: null,
        merLogoUrl:null,
        merName: null,
        merAddress: null,
        id:null,
        merId:null
      },
      rules: {
        merName: [{required: true, message: '商户名不能为空', trigger: 'blur'}],
        merAddress: [{required: true, message: '商户地址不能为空', trigger: 'blur'}],
        merLogo: [{required: true, message: '商户Logo不能为空', trigger: 'change'}],
      },
      sts: "",
      dialogVisible: false,
      disabled: false,
      companyCertPhotoLimit: 1,
      dialogImageUrl:null,
      logoFile:null,
      logo:null,
      fileList:[]
    }
  },
  mounted() {
    this.getSTSInfo();
    this.item.id = this.$route.params.id
    this.getItem();
  },
  methods: {
  getSTSInfo() {
    let _this = this
    adminHttp.get('/backend/aliyun/sts').then(result => {
      if (result && result.code === 200) {
        _this.sts = result.data;
      } else {
        _this.$message.error(result.message)
      }
    }).catch(error => {
    }).finally(() => {
    })
  },
    afterReadLogo(file) {
      this.logoFile = file;
      this.logo = window.URL.createObjectURL(file.file)
      //调用图片上传
      if (this.item.merLogo === ""||this.item.merLogo===null) {
        this.item.merLogo = uuid.v4() + ".jpg";
      }

      if (!this.beforeAvatarUpload(file.file)) {
        this.$message.error('上传头像图片大小不能超过 500KB!');
        this.fileList =[];
        return;
      }

      this.uploadFile(file.file, this.item.merLogo)
    },
    handleRemove(file, fileList) {
      this.fileList =[]
    },
    uploadFile(file, fileName) {
      const headers = {
        // 指定该Object被下载时网页的缓存行为。
        'Cache-Control': 'no-cache',
        // 指定该Object被下载时的名称。
        // 'Content-Disposition': 'oss_download.txt',
        // 指定该Object被下载时的内容编码格式。
        // 'Content-Encoding': 'UTF-8',
        // 指定过期时间。
        // 'Expires': 'Wed, 08 Jul 2022 16:57:01 GMT',
        // 指定Object的存储类型。
        // 'x-oss-storage-class': 'Standard',
        // 指定Object的访问权限。
        'x-oss-object-acl': 'private',
        // 设置Object的标签，可同时设置多个标签。
        // 'x-oss-tagging': 'Tag1=1&Tag2=2',
        // 指定CopyObject操作时是否覆盖同名目标Object。此处设置为true，表示禁止覆盖同名Object。
        'x-oss-forbid-overwrite': 'false',
      }
      let data = file;
      const client = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
        region: 'oss-cn-hangzhou',
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: this.sts.credentials.accessKeyId,
        accessKeySecret: this.sts.credentials.accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: this.sts.credentials.securityToken,
        // 填写Bucket名称。
        bucket: 'heshangjr-development'
      });
      try {
        // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
        // 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
        // data对象可以自定义为file对象、Blob数据或者OSS Buffer。
        const result = client.put(
            fileName,
            data,
            {headers}
        )
        console.log(result)
      } catch (e) {
        console.log(e);
        this.fileList = [];
      }
    },beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024  <= 500;
      return isLt2M;
    },
    getItem() {
      let _this = this
      let params = {
        id: _this.item.id
      }
      _this.fullscreenLoading = true
      adminHttp.post('/backend/merchant/querySingleMerchant', params).then(result => {
        if (result.code === 200) {
          _this.item.id = result.data.id
          _this.item.merName = result.data.merName
          _this.item.merId = result.data.merId
          _this.item.merAddress = result.data.merAddress
          _this.item.merLogo = result.data.merLogo
          _this.item.merLogoUrl = result.data.merLogoUrl
          if(_this.item.merLogoUrl&&_this.item.merLogo){
            let png ={
              name:_this.item.merLogo,
              url:_this.item.merLogoUrl
            }
            _this.fileList.push(png)
          }
        } else {
          _this.$message.error(result.message);
        }
      }).catch(error => {
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    },submit() {
      let _this = this;
      let params = {
        id:_this.item.id,
        merLogo: _this.item.merLogo,
        merName: _this.item.merName,
        merAddress: _this.item.merAddress
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          _this.fullscreenLoading = true
          adminHttp.post('/backend/merchant/editMerchantBasicInformation', params).then(result => {
            if (result.code === 200) {
              _this.$message.success('修改成功')
              _this.$router.push({path: '/admin/merchant_manage/index'})
            } else {
              _this.$message.error(result.message || '修改失败')
            }
          }).catch(error => {
            _this.$message.error('请求失败')
          }).finally(() => {
            _this.fullscreenLoading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
